import React from "react";
import { lazy, Suspense } from "react";
import { useRoutes } from "react-router-dom";

const Home = lazy(() => import("@/pages/homePage"));

const lazyLoad = (children) => {
  return <Suspense fallback={""}>{children}</Suspense>;
};

const AppRouter = () => {
  return useRoutes([
    {
      path: "/",
      excat: true,
      element: lazyLoad(<Home />),
    },
    {
      path: "/home",
      excat: true,
      element: lazyLoad(<Home />),
    },
  ]);
};

export default AppRouter;
